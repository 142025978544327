<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg" style="width:100%">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊和歌山必備的景點套票，精選和歌山多個人氣設施與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfwakayama/tc/havefun_title-tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfwakayama/01_sc.png'),
          title: ['NO PHONE NO LIFE之旅~秘密和歌山觀光~'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣和歌山市美園町5丁目12-2 wakachika 廣場 (觀光交流中心)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.wakayamakanko.com/wakayama_asobi/npnl/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含內容：智慧手機封印袋、透明文件夾、白色地圖、專用背包、旅行紀念品'] },
                { cid: 2, text: ['體驗時間：9:00～17:00 *不需預約'] },
                { cid: 3, text: ['請事先查看官方網站以確認營業時間。<br>※公休日：12月29日~1月3日'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfwakayama/02_sc.png'),
          title: ['和歌山拉麵+餃子 or 素食拉麵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣和歌山市美園町5-7-12'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間和休息日可能會有變更，請在光臨店鋪之前與店鋪確認。<br>※公休日：12月29日~1月3日'] },
                { cid: 2, text: ['素食拉麵不附餃子。'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfwakayama/03_sc.png'),
          title: ['紀州漆器傳統產業會館「URUWASHI館」館內1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒642-0001 和歌山縣海南市船尾222番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.kishusikki.com/hk/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請以現金支付額外費用。'] },
                { cid: 2, text: ['請在光臨前提前查看官方網站以確認營業時間。'] },
                { cid: 3, text: ['如果您想體驗漆器蒔繪，請提前通過電話進行申請。'] },
                { cid: 4, text: ['漆器蒔繪體驗的費用因尺寸而異。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfwakayama/04_sc.png'),
          title: ['紀三井寺特別參觀券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒641-0012　和歌山縣和歌山市紀三井寺1201'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.kimiidera.com/worship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含內容：參拜費、佛殿觀景迴廊入場費、大光明殿參拜費、往返纜車費用。'] },
                { cid: 2, text: ['祈禱、回向、參拜費用以及參拜時間各有不同。請至官網確認。'] },
                { cid: 3, text: ['大光明殿有不開放的日子。'] },
                { cid: 4, text: ['請事前至官網確認最新的營業狀況。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfwakayama/05_sc.png'),
          title: ['海南市物產觀光中心「KAIBUTSU-KUN」1,000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣海南市名高51-2 (JR海南站內)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.kainankanko.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['若有價差需現場支付差額。'] },
                { cid: 2, text: ['請事前至官網確認營業狀況。'] },
                { cid: 3, text: ['無須兌換成實體券，請在結帳時出示電子票。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfwakayama/06_sc.png'),
          title: ['和歌山格蘭比亞大酒店 大廳咖啡廳 PASSWORD 「蛋糕套餐」'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣和歌山市友田町5丁目18番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/tw/hotels/wakayama/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含內容：蛋糕、飲料。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。<br>※可能因臨時休業・客滿而無法使用。'] },
                { cid: 4, text: ['產品圖片僅供參考。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfwakayama/07_sc.png'),
          title: ['自行車租借・E-bike一日使用 1,000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣東牟婁郡那智勝浦町築地 6-1-4 (那智勝浦觀光案內所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['費用根據使用時間和自行車類型而異。'] },
                { cid: 3, text: ['無須預約。由於車輛數量有限，如果您想確保可使用，請提前通過電子郵件聯繫「travel@nachikan.jp」。'] },
                { cid: 4, text: ['營業時間請事先至官網確認。'] },
                { cid: 5, text: ['若有價差需現場支付差額。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfwakayama/08_sc.png'),
          title: ['平安服裝穿著體驗1,000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣東牟婁郡那智勝浦町築地 6-1-4 那智勝浦觀光案內所<br>和歌山縣東牟婁郡那智勝浦町野野 3034-2 大門坂觀光案內所'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。(只接受現金)'] },
                { cid: 3, text: ['大門坂茶屋沒有投幣式儲物櫃。僅提供帶有編號的黑色包包讓客人存放衣物。無法保管貴重物品，敬請諒解。'] },
                { cid: 4, text: ['營業時間請事先至官網確認。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfwakayama/09_sc.png'),
          title: ['「川柳」可選餐券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '那智勝浦町築地4-3-27'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/restaurant/1580/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['「秋刀魚壽司(附湯)・豬排丼(附湯)」擇一，或加500日圓可選名產鮪魚丼(附湯)。※僅收現金'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfwakayama/10_sc.png'),
          title: ['「Nigiwai-Ichiba」特製午餐兌換券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣東牟婁郡那智勝浦町築地7-12(店名:Maguro No Wakiguchi)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://nigiwaiichiba.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請事先查看官方網站以確認營業時間。<br>※公休日：週二'] },
                { cid: 2, text: ['最後點餐為15:30'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfwakayama/11_sc.png'),
          title: ['紀之松島遊覽船'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '和歌山縣東牟婁郡那智勝浦町勝浦442-20(紀之松島旅遊接待處)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['http://kinomatsushima.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請提前在網站上查看運行情況和時間表。<br>※休息日：週三（但是，暴風雨或淡季期間可能會臨時關閉）'] },
                { cid: 2, text: ['有可能會因為客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfwakayama/12_sc.png'),
          title: ['動物遊樂園 冒險大世界 1日入場劵1000日元優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒649-2201 和歌山縣西牟婁郡白濱町堅田2399番地 (售票窗口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。(只限入場劵)'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfwakayama/13_sc.png'),
          title: ['SHIRAHAMA KEY TERRACE Hotel Seamore「一日泡湯券」'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山縣西牟婁郡白濱町 1821 (櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間請事先至官網確認。'] },
                { cid: 2, text: ['有可能會因為客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfwakayama/14_sc.png'),
          title: ['白濱海中展望塔(Coral Princess) 入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山縣西牟婁郡白濱町 1821 (售票處)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請事先查看官方網站以確認營業時間。<br>※天氣不佳時可能會臨時休業。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFWakayama&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-wakayama-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>